// .card-collection-container {
//   padding: 30px 50px 0px 60px;
// }

@media only screen and (max-width: 800px) {
  .card-collection-container {
    padding: 10px 10px 0px;
  }
}

@media only screen and (max-width: 450px) {
  .card-collection-container {
    padding: 0px;
  }
}
